/**
 * THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
 * yarn icons:build
 */
import * as React from 'react';
import type { SVGProps } from 'react';
import { Ref, forwardRef } from 'react';
const FacebookIcon = (
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        ref={ref}
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M23 21.786c0 .67-.544 1.214-1.214 1.214h-5.607v-8.52h2.86l.428-3.32H16.18V9.04c0-.961.267-1.616 1.646-1.616h1.758v-2.97a23.503 23.503 0 0 0-2.562-.132c-2.535 0-4.27 1.548-4.27 4.39v2.448H9.884v3.32h2.867V23H2.214C1.544 23 1 22.456 1 21.786V2.214C1 1.543 1.544 1 2.214 1h19.572C22.456 1 23 1.543 23 2.214v19.572Z"
            clipRule="evenodd"
        />
    </svg>
);
const ForwardRef = forwardRef(FacebookIcon);
export default ForwardRef;
